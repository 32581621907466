import { Box, Flex, Heading } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import TabView from '../Components/TabView'

import GaleriView from '../Components/Galeri'




const yData=[
  "eK-nZxj7CKk",
  "Yr3_dJxUY5c",
	"i0Kb53oFfd4",
  "HSn0SZFlwV0",
  "msZj29AV6pM",
  "MU856bpPxz8",
	"1xbnwj9lh14"
]



function Galeri() {



   const createData =  (l,dot,endpoint)=>{
    
    let vdata = []
    for (let i = 1; i < l; i++) {
      vdata.push(endpoint+"/"+i+dot)
      console.log(endpoint+"/"+i+dot)
    }

    return vdata
  }
  
  
  
  
  
  const tt=[
    {name:"RESİMLER",child:<GaleriView mode="img" data={createData(18,".jpeg","/images") }/>},
    {name:"VİDEOLAR",child:<GaleriView mode="video" data={createData(3,".mp4","/videolar") }/>} ,
    {name:"LİNKLER",child:<GaleriView mode="link" data={yData}/>} ,
  
  ]

  return (
    <Box p={5}>
         <Flex mb={10} alignItems={"center"} flexDirection={"column"} >
      <Heading mb={0} mt={10} >GALERİ</Heading>
    </Flex>
    <TabView tabData={tt}  />
   
  </Box>
  )
}

export default Galeri