import { Box, Flex, Grid, GridItem, Heading } from '@chakra-ui/react'
import React from 'react'
import YCard from '../Components/YCard'

function DenetimKurulu() {

  const yonetimKurulu = [
    { name: "MEHMET GÜL", Gorev: "DENETLEME BAŞKANI ", img: "/uyeler/8.jpg", role: "ÜYE" },
    { name: "İBRAHİM CAN ", Gorev: "KURUL ÜYESİ", img: "/uyeler/10.jpeg", role: "ÜYE" },
    { name: "SÜLEYMAN ÖZBULUT", Gorev: "KURUL ÜYESİ", img: "/uyeler/7.jpeg", role: "ÜYE" },
  


  ]


  return (


    <Box p={5}>
      <Flex mb={10} justifyContent={"center"} >
        <Heading mt={10} >DENETİM KURULU</Heading>
      </Flex>

      <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", xl: "repeat(3, 1fr)" }} gap={6}>
        {yonetimKurulu.map((d) => (
          <GridItem key={d.name} w="100%" bg="yellow.500" display="flex" alignItems="center" justifyContent="center">
            <YCard avatar={d.img} key={d.name} name={d.Gorev} role={d.role} content={d.name} />
          </GridItem>
        ))}
      </Grid>
    </Box>
  )
}



export default DenetimKurulu