import { Box, Spinner } from '@chakra-ui/react'
import React from 'react'

function Loading({display=false}) {
  return (
    <Box w={"100%"} h={"100%"} top={0} left={0} display={display?"flex":"none"} justifyContent={"center"} alignItems={"center"} backgroundColor={"rgba(0,0,0,0.8)"} position={"fixed"} zIndex={9999} >

        <Spinner 
        thickness='4px'
        speed='0.65s'
        emptyColor='gray.200'
        color='blue.500'
        size='xl'
      />
    </Box>
  )
}

export default Loading