import { Box, Flex, Grid, GridItem, Heading, Stack } from '@chakra-ui/react'
import React from 'react'
import { RiMapPin2Line } from "react-icons/ri";
import GCard from '../Components/GCard';
function Sube() {

  const Subeler=[
    {name:"İSTANBUL ŞUBE",addr:"Kordonboyu, Üsküdar Cd. No:26/11, 34860 Kartal/İstanbul"},
    {name:"ANKARA ŞUBE",addr:"SIHHİYE MAH. İLKİZ SK. NO:20/4 ÇANKAYA/ANKARA"},


  ]

  return (
    <Box p={8} >
    <Flex mb={10} alignItems={"center"} flexDirection={"column"} >
      <Heading mb={10} mt={10} >ŞUBELERİMİZ</Heading>
    </Flex>

  <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", xl: "repeat(2, 1fr)" }} gap={6}>
      {Subeler.map((d) => (
        <GridItem key={d.name} display="flex" alignItems="center" justifyContent="center">
          <GCard name={d.name}  addr={d.addr} icon={<RiMapPin2Line fontSize={40} />} />
        

        </GridItem>
      ))


      }
    </Grid> 
  </Box>
  )
}

export default Sube