import { Box, Flex, Grid, GridItem, Heading } from '@chakra-ui/react'
import React from 'react'
import KCard from '../Components/KCard'
import TabView from '../Components/TabView'







const Dergi=()=>{
  return(
    <Box w={"100%"} height={500} textAlign={"center"} >Henüz Eklenmiş Bir Dergi Yok</Box>
  )
}

const Kitap=()=>{
  const yayınlar =[
    {yazar:"Dursun Güzel",name:"İnsanda İlahi Yaratıcı Güç",img:"/kitaplar/1.jpeg"},
    {yazar:"Haydar Şahin",name:"Sırr-ı Aşikar",img:"/kitaplar/2.jpeg"},
    {yazar:"Fatima Özcan",name:"Sayılarla Nedenselliğini Bilme",img:"/kitaplar/3.jpeg"},
    {yazar:"Haydar Şahin",name:"Değişim Kafi İrfani",img:"/kitaplar/4.jpeg"},
    {yazar:"Celal Metin",name:"Kuran-ı Kerim Fihristi",img:"/kitaplar/5.jpeg"},
    {yazar:"Dursun Güzel",name:"Hilafet Sonrası Alevi İnancında İlam ve Kuran",img:"/kitaplar/6.jpeg"},
    {yazar:"Enis Emir",name:"Konuşan Kuran",img:"/kitaplar/7.jpeg"},
    {yazar:"Dursun Güzel",name:"Seyyid Mahmut Hayranı",img:"/kitaplar/8.jpeg"},
    {yazar:"Hüseyin Boy",name:"Yaşamı ve Anılarıyla Seyyid Hasan Efendi",img:"/kitaplar/9.jpeg"},
    {yazar:"Abdulbaki GölPınarlı",name:"Yeni Gülzar-ı Haseneyn Kerbela Vak'ası",img:"/kitaplar/10.jpeg"},
    {yazar:"Fatima Özcan",name:"Sayının Sırrı",img:"/kitaplar/11.jpeg"},
    {yazar:"Fatima Özcan",name:"Zerrenin Realitesi",img:"/kitaplar/12.jpeg"},
    {yazar:"Hüseyin Nihal Atsız",name:"Aşıkpaşalıoğlu Tarihi",img:"/kitaplar/13.jpeg"},
    {yazar:"Haydar Şahin",name:"Ma'rifet",img:"/kitaplar/14.jpeg"},
    {yazar:"Seyit Mürsel Kami",name:"Rıza-i Hak",img:"/kitaplar/15.jpeg"},
    {yazar:"Hasan İpçi",name:"Menakıb-ı Ali Resul",img:"/kitaplar/16.jpeg"},

  ]


  return(
    <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)", xl: "repeat(4, 1fr)" }} gap={6}>
    {yayınlar.map((d) => (
      <GridItem key={d.name} display="flex" alignItems="center" justifyContent="center">
        {/* <GCard name={d.name}  addr={d.addr} icon={<RiMapPin2Line fontSize={40} />} /> */}
      <KCard name={d.yazar} title={d.name} img={d.img}  />

      </GridItem>
    ))


    }
  </Grid> 
  )
}



function Yayimlar() {
  const tt=[
    {name:"Kitaplar",child: <Kitap />},
    {name:"Dergiler",child: <Dergi />},
  
  ]

  return (
    <Box p={8} >
    <Flex  alignItems={"center"} flexDirection={"column"} >
      <Heading mb={10} mt={10} >YAYINLAR</Heading>
    </Flex>

    <TabView tabData={tt}  />

  </Box>
  )
}

export default Yayimlar