import { Box, Flex, Heading } from '@chakra-ui/react'
import React from 'react'
import TabView from '../Components/TabView'

function KoseYazisi() {
  const tt=[
    {name:"Akademi",child: ""},
    {name:"Basın",child: ""},
    {name:"Ehlibeyt Divanı",child: ""},
    {name:"Yazarlar",child: ""},
    {name:"Hak Aşıkları ",child: ""},

  ]

  return (
    <Box p={8} >
    <Flex  alignItems={"center"} flexDirection={"column"} >
      <Heading mb={10} mt={10} >KÖŞE YAZISI</Heading>
    </Flex>

    <TabView tabData={tt}  />

  </Box>
  )
}

export default KoseYazisi