import { Box, Flex, Grid, GridItem, Heading, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import GCard from '../Components/GCard'
import { RiBankLine } from "react-icons/ri";


function Odeme() {
  const Subeler=[
    {name:"HALK BANK YENİŞEHİR ŞUBESİ / ANKARA",addr:"IBAN: TR 29 0001 2009 2120 0016 000130",text:"SEYYİD HACI KUREYŞ DERGÂHI ARAŞTIRMA EĞİTİM KÜLTÜR VE SOSYAL YARDIMLAŞMA DERNEĞİ"},


  ]
  return (
    <Box p={8} >
    <Flex mb={10} alignItems={"center"} flexDirection={"column"} >
      <Heading mb={10} mt={10} >AİDAT VE BAĞIŞ</Heading>
      
    </Flex>

  {/* <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", xl: "repeat(3, 1fr)" }} gap={6}> */}
      {Subeler.map((d) => (
        // <GridItem key={d.name} display="flex" alignItems="center" justifyContent="center">
          // <GCard name={d.name}  addr={d.addr} icon={<RiBankLine fontSize={40} />} />
          <Stack  spacing={3} >

          <Box fontSize={30} display={"flex"} alignItems={"center"} flexDirection={"column"} minH={200} color={"gray.100"} bg={"gray.600"} p={6} >
           
          <RiBankLine  fontSize={60} />
          <Heading mt={10} color={"gray.100"} mb={5} > {d.name} </Heading>
          
          <Text mb={2} fontSize={15} > {d.text} </Text>
         {d.addr}
          </Box>
        </Stack>

        // </GridItem>
      ))


      }
    {/* </Grid>  */}
  </Box>
  )
}

export default Odeme