import { Box, Flex, Grid, GridItem, Heading } from '@chakra-ui/react'
import React from 'react'
import SCard from '../Components/SCard'

function Sempozyum() {


  const sempozyum = [
    {date:"23 Mart 2024",yer:"ANKARA",title:"Ankara Seyyid Kureyş Sempozyumu",img:"/sempozyun/1.jpeg",text:"23 Mart 2024 tarihinde Ankara ilinde düzenlenen sempozyum başarıyla gerçekleştirilmiştir. Etkinlik boyunca, katılımcılar arasında olumlu ve verimli görüşmeler yapılmış, [konu/alan] ile ilgili son gelişmeler ve araştırma sonuçları paylaşılmıştır. Sempozyum, alanında uzman kişilerin bilgi ve deneyimlerini aktardığı oturumlar ve panellerle zenginleştirilmiş olup, katılımcılar arasında güçlü bir ağ oluşturulmasına katkı sağlamıştır." }
  ]


  return (
  <Box p={8} >
      <Flex mb={10} alignItems={"center"} flexDirection={"column"} >
        <Heading mb={10} mt={10} >SEMPOZYUMLAR</Heading>
      </Flex>
 
    <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", xl: "repeat(3, 1fr)" }} gap={6}>
        {sempozyum.map((d) => (
          <GridItem  key={d.name} display="flex" alignItems="center" justifyContent="center">
             <SCard text={d.text} header={d.title} img={d.img} date={d.date} yer={d.yer}/>
 
          </GridItem>
        ))}
      </Grid>
  </Box>
  )
}

export default Sempozyum