import { Box, Flex, Heading, Image, Stack, useColorModeValue } from '@chakra-ui/react'
import React from 'react'



function HomePage() {
    return (
        <Flex alignItems={"center"} flexDirection={"column"} >
            <Heading mt={10} >SEYYİDİ SADET EVLADI RESULLERİN WEB SİTESİ</Heading>

            <Stack
                direction={{ base: 'column', md: 'row' }}
                textAlign="center"
                justify="center"
                spacing={{ base: 4, lg: 10 }}
                py={10}
            >
                <Box
                   bgColor={useColorModeValue('gray.200', 'gray.500')}
                   justifyContent={"center"}
                   alignItems={"center"}
                   display={"flex"}
                    p={6}
                    shadow="base"
                    borderWidth="1px"
                    borderColor={useColorModeValue('gray.200', 'gray.500')}
                    borderRadius={'xl'} >
                    {/* <Image objectFit={"cover"} src='/sol.jpeg' />
                     */}

                     <Box backgroundPosition={"center"} backgroundSize={"cover"} backgroundRepeat={"no-repeat"} backgroundImage={"url('/sol.jpeg')"}  width={200} height={400} />
                </Box>

                <Box
                bgColor={useColorModeValue('gray.200', 'gray.500')}
                    justifyContent={"center"}
                    alignItems={"center"}
                    display={"flex"}
                    p={6}
                    shadow="base"
                    borderWidth="1px"
                    borderColor={useColorModeValue('gray.200', 'gray.500')}
                    borderRadius={'xl'} 
                    
                    >
                    {/* <Image    objectFit={"contain"} src='/ortam.jpeg' />
                     */}
                     <Box backgroundPosition={"center"} backgroundSize={"cover"} backgroundRepeat={"no-repeat"} backgroundImage={"url('/ortam.jpeg')"}   width={400} height={400} />

                </Box>

                <Box
                   bgColor={useColorModeValue('gray.200', 'gray.500')}
                   justifyContent={"center"}
                   alignItems={"center"}
                   display={"flex"}
                    p={6}
                    shadow="base"
                    borderWidth="1px"
                    borderColor={useColorModeValue('gray.200', 'gray.500')}
                    borderRadius={'xl'} >
                    {/* <Image objectFit={"cover"} src='/sag.jpeg' /> */}
                     <Box backgroundPosition={"center"} backgroundSize={"cover"} backgroundRepeat={"no-repeat"} backgroundImage={"url('/sag.jpeg')"} width={200} height={400} />
                
                </Box>

            </Stack>

        </Flex>
    )
}

export default HomePage