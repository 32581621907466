import React, { useState } from 'react'
import { Document, Page } from 'react-pdf';
import Sample from '../Components/PDF/Sample';
import { Box, Flex, Heading } from '@chakra-ui/react';
import TabView from '../Components/TabView';


const tt=[
  {name:"ŞECERE ORJİNAL",child: <Sample file={"/files/SERECE.pdf"} />},
  {name:"ŞECERE TÜRKÇE",child: <Sample file={"/files/SECERE_TURKCE.pdf"} />},

]

function Secere() {

  return (
    <Box p={5}>
          <Flex mb={10} alignItems={"center"} flexDirection={"column"} >
      <Heading mb={0} mt={10} >ŞECERE</Heading>
    </Flex>
    
      <TabView tabData={tt}  />
     
    </Box>
  )
}

export default Secere

