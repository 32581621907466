import React from 'react'
import { useOutlet } from 'react-router-dom'
import AppBar from '../Components/AppBar'
import LargeWithLogoLeft from '../Components/Footer'


function BaseLayout() {
  const outlet = useOutlet()
  return (
    <div  >
      <AppBar />
      <div style={{width:"100%",height:80}} ></div>
      {outlet}

      <LargeWithLogoLeft />
    </div>
  )
}

export default BaseLayout