import { Box, Button, Card, CardBody, CardFooter, CardHeader, Flex, Heading, IconButton, Image, Text } from '@chakra-ui/react'
import React from 'react'


function SCard({ header, text, img, date, yer }) {
    return (
        <Card rounded={"2%"} shadow={"base"} m={0} bg={'gray.300'} maxW='md'>
            <CardHeader textAlign={"center"} fontWeight={"bold"} >
                {header}
            </CardHeader>

            <Image
                objectFit='cover'
                src={img}
            />
            <CardBody>
                <Text>
                    {text}
                </Text>
            </CardBody>
            <CardFooter
                justify='space-between'
                flexWrap='wrap'
                sx={{
                    '& > button': {
                        minW: '136px',
                    },
                }}
            >
                <Button flex='1' variant='ghost' >
                    {date}
                </Button>
                <Button flex='1' variant='ghost' >
                    {yer}
                </Button>
            </CardFooter>
        </Card>
    )
}

export default SCard