import { Box, Container, Flex, Heading, Image, chakra } from '@chakra-ui/react';
import React from 'react'




const maddeler = [
  "Özelde Seyit Hacı Kureyş ve evlatlarının genelde seyyid aile ocaklarının İslam’ın alevi yorumlarına etkilerini, katkılarını, yaşamlarını araştırmak ve kamuoyuna tanıtmak,",
  "Seyit Hacı Kureyş evlatlarının yaşamını geçirdiği mekanların imarı, inşası, onarımı, bakımını yapmak, yaptırmak ve kamuoyuna tanıtmak, 2863 sayılı Kültür ve Tabiat Varlıklarını Koruma Kanunu kapsamında olan, Kureyş ve evlatlarının mal varlıkları tespit edildikten sonra ilgili kurullarca imar ve projelerini yaptırıp Kurul onayından geçtikten sonra onları hizmete açmak bu tür yerlerin yurt içi ve yurt dışı olmak kaydıyla gerekli araştırmalar sonucunda hizmete hazır hale getirmek bu tür yaptırımlar için ilgili kuruluşlardan yardım ve katkı sağlamak, izin alınarak işletmeciliğini yapmak,",
  "İkinci maddede zikredilen mekanları ziyarete gelenlerin dinlenmesi, barınması ağırlanması için tesisler ve cem evleri yapmak ya da yapılanlara ayni ve nakdi katkıda bulunmak,",
  "Din, dil, ırk, mezhep, sınıf, cinsiyeti nedeniyle temel hak ve özgürlükleri kısıtlanan insanların hakkını ve hukukunu her zeminde savunmak,",
  "Dernek amacını gerçekleştirmek için kurullar, kollar, komiteler, komisyonlar oluşturmak,",
  "Faaliyetlerin etkinleştirilmesi ve geliştirilmesi için araştırmalar yapmak, Kurs, seminer, konferans ve panel gibi eğitim çalışmaları düzenlemek,",
  "Amacın gerçekleştirilmesi için gerekli olan her türlü bilgi, belge, doküman ve yayınları temin etmek, dokümantasyon merkezi oluşturmak, çalışmalarını duyurmak için amaçları doğrultusunda gazete, dergi, kitap gibi yayınlar ile üyelerine dağıtmak üzere çalışma ve bilgilendirme bültenleri çıkarmak, internetin sağladığı tüm olanakları kullanmak,",
  "Amacın gerçekleştirilmesi için sağlıklı bir çalışma ortamını sağlamak, her türlü teknik araç ve gereci, demirbaş ve kırtasiye malzemelerini temin etmek,",
  "Gerekli izinler alınmak şartıyla yardım toplama faaliyetlerinde bulunmak ve yurt içinden ve yurt dışından bağış kabul etmek,",
  "Tüzük amaçlarının gerçekleştirilmesi için ihtiyaç duyduğu gelirleri temin etmek amacıyla iktisadi, ticari ve sanayi işletmeleri kurmak, işletmek,",
  "Üyelerinin yararlanmaları ve boş zamanlarını değerlendirebilmeleri için lokal açmak, sosyal ve kültürel tesisler kurmak ve bunları tefriş etmek, Boş zamanlarını değerlendirmek için konunun uzmanlarınca konferanslar ve paneller düzenlemek.",
  "Üyeleri arasında beşeri münasebetlerin geliştirilmesi ve devam ettirilmesi için yemekli toplantılar, konser, balo, tiyatro, sergi, spor, gezi, festival ve eğlenceli etkinlikler vb. düzenlemek veya üyelerinin bu tür etkinliklerden yararlanmalarını sağlamak,",
  "Dernek faaliyetleri için ihtiyaç duyulan taşınır, taşınmaz mal satın almak, satmak, kiralamak, kiraya vermek ve taşınmazlar üzerinde ayni hak tesis etmek,",
  "Derneğin amacını gerçekleştirmek üzere, benzer amaçlı derneklerden, vakıflardan, işçi işveren ve mesleki kuruluşlardan maddî yardım almak ve adı geçen kurumlara maddî yardımda bulunmak,",
  "Amacın gerçekleştirilmesi için gerek görülmesi halinde, 5072 sayılı Dernek ve Vakıfların Kamu Kurum ve Kuruluşları ile İlişkilerine Dair Kanun hükümleri saklı kalmak üzere, kamu kurum ve kuruluşları ile görev alanlarına giren konularda ortak projeler yürütmek,",
  "Gerekli görülen yerlerde şube ve temsilcilikler açmak,",
  "Derneğin amacı ile ilgisi bulunan ve kanunlarla yasaklanmayan alanlarda, diğer derneklerle veya vakıf, sendika ve benzeri sivil toplum kuruluşlarıyla ortak bir amacı gerçekleştirmek için platformlar oluşturmak."
];


function Amac() {
  return (
    <Container maxW='2xl' >
      <Flex mb={10} alignItems={"center"} flexDirection={"column"} >
        <Heading mb={10} mt={10} >AMAÇ VE FAALİYETLER</Heading>
        <Image rounded={"2%"} objectFit={"cover"} src='/alevi.jpg' />
      </Flex>


      <Flex mb={10} textAlign={"center"} alignItems={"center"} flexDirection={"column"} >
        <chakra.p mt={10} fontFamily={'Work Sans'} fontWeight={'bold'} fontSize={18} color={"#000"} >
          Derneğin Amacı ve Bu Amacı Gerçekleştirmek İçin Dernekçe Sürdürülecek Çalışma
          Konuları ve Biçimleri İle Faaliyet Alanı
        </chakra.p>
      </Flex>
      <chakra.p mt={5} fontFamily={'Work Sans'} fontWeight={'medium'} fontSize={18} color={"#000"} >
        Dernek, Alevi İslam anlayışını; inanç, ibadet, öğretisini ve bu öğretiyi tüm
        zamanlarda yaşamış ve bu zamana kadar taşımış ocak’ları, seyit’leri, dede’leri, baba’ları,
        ozan’ları; mekan, tarih, kültür, ekonomi, sosyal ve diğer tüm boyutları ile araştırmak, tanıtmak,
        öğretmek, yaşatmak. Farklı inancı, düşünceyi, kültürü, yaşamı seçen insan ve toplumlar
        arasında barış, dayanışma ve hoşgörünün geliştirilmesine katkıda bulunmak amacı ile
        kurulmuştur.
      </chakra.p>

      <Flex mb={10} alignItems={"center"} flexDirection={"column"} >
        <chakra.p mt={10} fontFamily={'Work Sans'} fontWeight={'bold'} fontSize={18} color={"#000"} >
          Dernekçe Sürdürülecek Çalışma Koşulları ve Biçimleri
        </chakra.p>
      </Flex>
      <ul>
        {maddeler.map((d, i) => {
          return (
            <li key={i}>
              <chakra.p fontFamily={'Work Sans'} fontWeight={'medium'} fontSize={18} color={"#000"} >{d} </chakra.p>
            </li>

          )

        })}

      </ul>
      <chakra.p mt={5} fontFamily={'Work Sans'} fontWeight={'medium'} fontSize={18} color={"#000"} >
      Seyyid Kureyş Ocağı adını kullanan yada kullanacak inisiyatif, platform, birlik, dernek, vakıf
ve benzeri tüzel kişiliğe haiz olan veya olmayan her türlü oluşumlar; Tunceli ili Nazimiye İlçesi
Deva Kureyş’de (Zeve Köyünde) bulunan Seyyid Kureyş Ocağı değerlerine bağlı olarak
faaliyetlerini yürütürler.
      </chakra.p>

      <Flex mb={10} alignItems={"center"} flexDirection={"column"} >
        <chakra.p mt={10} fontFamily={'Work Sans'} fontWeight={'bold'} fontSize={18} color={"#000"} >
        Dernek Faaliyet Alanı
        </chakra.p>
      </Flex>
      <chakra.p mt={5} fontFamily={'Work Sans'} fontWeight={'medium'} fontSize={18} color={"#000"} >
      Dernek İnançsal, Kültürel, Sosyal ve Eğitim alanlarında faaliyet gösterir.
      </chakra.p>

      <Box w={"100%"} height={100} ></Box>
    </Container>
  )
}

export default Amac