import { Box, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs,css } from '@chakra-ui/react'
import React from 'react'




function TabView({tabData}) {
  return (
    <Tabs align='center' position='relative' variant='enclosed'>
    <TabList >
        {tabData&&tabData.map((d,i)=>{
            return(
                <Tab key={i}>{d.name}</Tab>

            )
                
            
        })}
    </TabList>

    <TabIndicator mt='-1.5px' height='2px' bg='blue.500' borderRadius='1px' />
    <TabPanels>
    {tabData&&tabData.map((d,i)=>{
            return(
                <TabPanel key={i}>
               {d.child}
              </TabPanel>
            )
        })}
    </TabPanels>
  </Tabs>
  )
}

export default TabView