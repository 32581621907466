import { Box, Flex, Heading, Stack } from '@chakra-ui/react'
import React from 'react'

function GCard({name,addr,icon}) {
  return (
    <Stack  spacing={3} >

    <Box  display={"flex"} alignItems={"center"} flexDirection={"column"} minH={200} color={"gray.100"} bg={"gray.600"} p={6} >
      <Flex mb={5} p={3}  >
   {icon}
    <Heading color={"gray.100"} ml={5} > {name} </Heading>
      </Flex>
   {addr}
    </Box>
  </Stack>
  )
}

export default GCard