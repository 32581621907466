import { Box, Flex, Heading } from '@chakra-ui/react'
import React from 'react'
import Foms from '../Components/Foms'

function Formlar() {

  
  return (
    <Box p={6}>
      <Flex mb={10} alignItems={"center"} flexDirection={"column"} >
        <Heading mb={0} mt={10} >Üyelik Formu</Heading>
      <Foms />
      </Flex>
    </Box>
  )
}

export default Formlar