import { ReactNode } from 'react';

import {
  Box,
  Container,
  Image,
  Link,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';


const date= new Date()


const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  );
};

export default function LargeWithLogoLeft() {
  return (
    <Box
      bg={useColorModeValue('gray.800', 'gray.900')}
      color={useColorModeValue('gray.200', 'gray.200')}>
      <Container as={Stack} maxW={'6xl'} py={10}>
        <SimpleGrid
          templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr 1fr 1fr' }}
          spacing={8}>
          <Stack spacing={6}>
            <Box>
              <Image w={20} h={20} src='/LOGO.jpg' />
            </Box>
            <ListHeader>SEYYİD HACI KUREYŞ DERGÂHI
ARAŞTIRMA EĞİTİM KÜLTÜR VE SOSYAL YARDIMLAŞMA
DERNEĞİ</ListHeader>
            <Text fontSize={'sm'}>
            Copyright © {date.getFullYear()} Relteco Teknoloji Sanayi ve Ticaret Ltd. Şti. Tüm Hakları Saklıdır.
            </Text>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Kurumsal</ListHeader>
            <Link href={'/YonetimKurulu'}>Yönetim Kurulu</Link>
            <Link href={'/DenetimKurulu'}>Denetim Kurulu</Link>
            <Link href={'/YayinKurulu'}>Yayın Kurulu</Link>
            <Link href={'/Tarihce'}>Tarihçe</Link>
            <Link href={'/Secere'}>Şecere</Link>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Faaliyetlerimiz</ListHeader>
            <Link href={'/Sempozyum'}>Sempozyum</Link>
            <Link href={'/Toplantı'}>Toplantı</Link>
            <Link href={'/Sube'}>Şubeler</Link>
            <Link href={'/Formlar'}>Formlar</Link>
            <Link href={'/Odeme'}>Aidat ve Bağış</Link>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Tanımak</ListHeader>
            <Link href={'/Galeri'}>Galeri</Link>
            <Link href={'/Yayimlar'}>Yayımlar</Link>
            <Link href={'/KoseYazisi'}>Köşe Yazısı</Link>
            <Link href={'/Iletisim'}>İleitşiim</Link>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Sosyal Medya</ListHeader>
            <Link target='_blank' href={'#'}>Facebook</Link>
            <Link target='_blank' href={'https://x.com/kureysandernegi'}>Twitter</Link>
            <Link target='_blank' href={'#'}>Instagram</Link>
            <Link target='_blank' href={'https://www.youtube.com/channel/UCAYLOs-74cfDOSraihM2VDQ'}>Youtube</Link>

            
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  );
}