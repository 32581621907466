import { Box, Flex, Grid, GridItem, Heading, Image } from '@chakra-ui/react'
import React from 'react'
import YouTube from 'react-youtube';
function GaleriView({mode,data}) {
  return (
    <Box p={8} >

  <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", xl: "repeat(3, 1fr)" }} gap={6}>
      {data&&data.map((d,i) => {
if (mode=="img") {
    return(
    <GridItem bg={"gray.600"} maxH={300} overflow={"hidden"} p={3} key={i} display="flex" alignItems="center" justifyContent="center">
    <Image w={"100%"} height={"100%"} objectFit={"cover"} src={d} />
            
    </GridItem>
    
    )
    
}
else if (mode=="video") {
    return(
        <GridItem bg={"gray.600"} maxH={300} key={i} display="flex" alignItems="center" justifyContent="center">
    
        <video
           
            controls
            // width={400}
        >
           <source  src={d} type="video/mp4"></source>
        </video>

                
         </GridItem>
        
        )
}
else if (mode=="link") {
    return(
      <GridItem bg={"gray.600"} maxH={300} key={i} display="flex" alignItems="center" justifyContent="center">
    
    <iframe
      width="100%"
      height={300}
       src={`https://www.youtube.com/embed/${d}`}
      // src={d}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
              
       </GridItem>
    )
}
      })
      }
    </Grid> 
  </Box>
  )
}

export default GaleriView