import React from 'react'
import YCard from '../Components/YCard'
import { Box, Flex, Grid, GridItem, Heading, VStack } from '@chakra-ui/react'

function YonetimKurulu() {


  const yonetimKurulu = [
    { name: "MEHMET GÜZEL", Gorev: "DERNEK BAŞKANI", img: "/uyeler/1.jpeg", role: "ÜYE" },
    { name: "HAYDAR BABA ", Gorev: "BAŞKAN YARDIMCISI", img: "/uyeler/5.jpeg", role: "ÜYE" },
    { name: "MEHMET BABA", Gorev: "BAŞKAN YARDIMCISI", img: "/uyeler/6.jpeg", role: "ÜYE" },
    { name: "FADİME ÖZCAN", Gorev: "HALKLA İLİŞKİLER", img: "/uyeler/2.jpeg", role: "ÜYE" },
    { name: "TAYLAN ÇOKYİĞİT", Gorev: "HALKLA İLİŞKİLER", img: "/uyeler/3.jpeg", role: "ÜYE" },
    { name: "GAZİ AKBABA ", Gorev: "MUHASİP", img: "/uyeler/4.jpeg", role: "ÜYE" },
    { name: "ZEYNEL ATEŞ", Gorev: "MUHASİP", img: "/uyeler/9.jpeg", role: "ÜYE" },


  ]


  return (


    <Box p={5}>
      <Flex mb={10} justifyContent={"center"} >
        <Heading mt={10} >YÖNETİM KURULU</Heading>
      </Flex>

      <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", xl: "repeat(3, 1fr)" }} gap={6}>
        {yonetimKurulu.map((d) => (
          <GridItem key={d.name} w="100%" bg="teal.500" display="flex" alignItems="center" justifyContent="center">
            <YCard avatar={d.img} key={d.name} name={d.Gorev} role={d.role} content={d.name} />
          </GridItem>
        ))}
      </Grid>
    </Box>
  )
}

export default YonetimKurulu