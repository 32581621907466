import { Box, Flex, Heading, Image } from '@chakra-ui/react'
import React from 'react'
import Sample from '../Components/PDF/Sample'



const tt=[
  {name:"İLKELERİMİZ",child: <Sample file={"/files/SERECE.pdf"} />},

]
function Ilkeler() {
  return (

    <Box p={5}>
       <Flex mb={0} alignItems={"center"} flexDirection={"column"} >
        <Heading mb={0} mt={10} >İLKELERİMİZ</Heading>
      </Flex>
      <Sample file={"/files/Tuzuk.pdf"} />
     
    </Box>
  )
}

export default Ilkeler