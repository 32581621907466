import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, CloseButton, Flex } from '@chakra-ui/react'
import React from 'react'

function FAlert({status="success",onOpen,onClose,isVisible,title,text}) {

  return isVisible&&(
    <Box w={"100%"} h={"100%"} top={0} left={0} display={"flex"} justifyContent={"center"} alignItems={"center"} backgroundColor={"rgba(0,0,0,0.8)"} position={"fixed"} zIndex={9999} >
    <Alert
    w={300}
    status={status}
    variant='subtle'
    flexDirection='column'
    alignItems='center'
    justifyContent='center'
    textAlign='center'
  >
    <CloseButton
        alignSelf='flex-end'
        position='relative'
        right={-1}
        top={-1}
        onClick={onClose}
      />
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"} >

    <AlertIcon boxSize='40px' mr={0} />
    <AlertTitle mt={4} mb={1} fontSize='lg'>
      {title}
    </AlertTitle>
    <AlertDescription maxWidth='sm'>
    {text}
    </AlertDescription>
    <Button bg={status=="success"?"green.400":"red.400"}  mt={10} onClick={onClose} >
      Tamam
    </Button>
    </Box>
  </Alert>

    </Box>
  )
}

export default FAlert