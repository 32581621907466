
import './App.css';
import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from './Pages/HomePage';
import BaseLayout from './Layouts/BaseLayout';

import YonetimKurulu from './Pages/YonetimKurulu';
import DenetimKurulu from './Pages/DenetimKurulu';
import YayinKurulu from './Pages/YayinKurulu';
import Tarihce from './Pages/Tarihce';
import Secere from './Pages/Secere';
import Amac from './Pages/Amac';
import Ilkeler from './Pages/Ilkeler';

import Sempozyum from './Pages/Sempozyum';
import Toplantı from './Pages/Toplantı';

import Sube from './Pages/Sube';
import Formlar from './Pages/Formlar';
import Odeme from './Pages/Odeme';
import Galeri from './Pages/Galeri';
import Yayimlar from './Pages/Yayimlar';
import KoseYazisi from './Pages/KoseYazisi';
import Iletisim from './Pages/Iletisim';
function Error404() {
  return (
    <div>
      <h2>Aradığınız Sayfa Bulunamadı</h2>
    </div>
  )
}

function App() {

  return ( 
    <>
      <Suspense fallback={<p>Yükleniyor....</p>} >
        <Routes>
          <Route element={<BaseLayout />} >
            <Route path="/" element={<HomePage />} />

            <Route path="/YonetimKurulu" element={<YonetimKurulu />} />
            <Route path="/DenetimKurulu" element={<DenetimKurulu />} />
            <Route path="/YayinKurulu" element={<YayinKurulu />} />
            <Route path="/Tarihce" element={<Tarihce />} />
            <Route path="/Secere" element={<Secere />} />
            <Route path="/Amac" element={<Amac />} />
            <Route path="/Ilkeler" element={<Ilkeler />} />

            <Route path="/Sempozyum" element={<Sempozyum />} />
            <Route path="/Toplantı" element={<Toplantı />} />

            <Route path="/Sube" element={<Sube />} />
            <Route path="/Formlar" element={<Formlar />} />
            <Route path="/Odeme" element={<Odeme />} />
            <Route path="/Galeri" element={<Galeri />} />
            <Route path="/Yayimlar" element={<Yayimlar />} />
            <Route path="/KoseYazisi" element={<KoseYazisi />} />
            <Route path="/Iletisim" element={<Iletisim />} />


          </Route>

          <Route path="*" element={<Error404 />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
