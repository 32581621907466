import { useCallback, useState } from 'react';
import { useResizeObserver } from '@wojtekmaj/react-hooks';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { Box, Button, Flex } from '@chakra-ui/react';
import "./Sample.css"
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js',
//   import.meta.url,
// ).toString();


const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

const resizeObserverOptions = {};

const maxWidth = 800;


export default function Sample({ file }) {
  const [numPages, setNumPages] = useState();
  const [containerRef, setContainerRef] = useState(null);
  const [containerWidth, setContainerWidth] = useState();

  const onResize = useCallback((entries) => {
    const [entry] = entries;

    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);

  useResizeObserver(containerRef, resizeObserverOptions, onResize);


  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  return (
    <>
      <Flex mb={0} alignItems={"center"} flexDirection={"column"} >
        <a href={file} download >
          <Button m={10}>İndirmek İçin Tıklayın</Button>
        </a>
      </Flex>

      <Flex justifyContent={"center"} w={"100%"} bg={"gray.200"} height={600} overflow={"auto"} >
        <div className="Example__container__document" ref={setContainerRef}>
          <Document file={file} onLoadSuccess={onDocumentLoadSuccess} options={options}>
            {Array.from(new Array(numPages), (el, index) => (
              <Box mt={10} >
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}

                />

              </Box>
            ))}
          </Document>

        </div>

      </Flex>
    </>


  );
}