import { Card, CardBody, Flex, Heading, Image, Stack, Text } from '@chakra-ui/react'
import React from 'react'

function KCard({title,name,img}) {
  return (
    <Card  bg={"gray.200"} maxW='sm'>
    <CardBody>
        <Flex justifyContent={"center"} >
      <Image
      h={400}
        src={img}
        borderRadius='lg'
      />

        </Flex>
      <Stack mt='6' spacing='3'>
        <Heading size='md'> {title} </Heading>
        <Text>
         {name}
        </Text>
      </Stack>
    </CardBody>
  
  </Card>
  )
}

export default KCard