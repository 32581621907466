import { Box, Container, Flex, Heading, Image,chakra } from '@chakra-ui/react'
import React from 'react'

function Tarihce() {
  return (
   <Container maxW='2xl' >
     <Flex mb={10} alignItems={"center"} flexDirection={"column"} >
        <Heading mb={10} mt={10} >TARİHÇE</Heading>
      <Image rounded={"2%"} objectFit={"cover"} src='/ortam.jpeg' />
      </Flex>
  
    <chakra.p mt={5} fontFamily={'Work Sans'} fontWeight={'medium'} fontSize={18} color={"#000"} >
    Ortadoğu'nun dört bin yıllık tarihi incelendiğinde Kureyş kabilesinin Haşimi kolu
Allah'ın yakın dostu Hz. Halil İbrahim soyunun devamıdır. Kureyş'in Hz. Halil
İbrahim soyundan geldiğini tüm kutsal kitaplar yazar. Ayrıca Kuran-ı Kerim'de
sayısız ayetlerle belgeler mevcuttur. Yedinci İmam Musa-i Kazım soyundan
geldiği bilinmekte olan Seyyid Mahmut Kebir, Seyyid olduğu için halk tarafından
değer görür. Kendisinin Evladı Resul olduğu ve keramet gösterdiği bilinmektedir.
Seyyid Mahmut Kebir Selçuklu Sultanı IV. Alaattin Keykubat tarafından fırına
atılır, yanmayan Seyyid Mahmut Kebir Hz. Muhammed'in büyük dedesi olan
Kureyş adıyla anılır. Soy şeceresi yazılıdır. Kendisine biat edenler tarafından
Seyyit Mahmud'u Kebir'e Seyyit Mahmut Hayrani ismi verilir.  
    </chakra.p>
    <chakra.p mt={5} fontFamily={'Work Sans'} fontWeight={'medium'} fontSize={18} color={"#000"} >
    Öz oğlu Düzgün Baba sır olup mekana çekilir. Düzgün Baba dağı ziyaretgah olur.
Bu ziyaretgah bugün Tunceli ilinin Nazimiye ilçesi sınırları içerisindedir.
Kureyş'in üçüncü kardeşi Mevali (Küçük Ali)'dir. Yaşamı hakkında yeterince
bilgi yoktur. Pir Sultan Abdal'ın deyişlerinde kerametlerinden bahsedilir.  
    </chakra.p>
    <chakra.p mt={5} fontFamily={'Work Sans'} fontWeight={'medium'} fontSize={18} color={"#000"} >
    Seyyid Kureyş adı (Kureyş), künyesi Mahmut, doğum yeri ve tarihi Hısnı Mansur
(Adıyaman), Hicri 535, Miladi 1141'dir. Dünyaya geldiğinde babası Seyyid Şeyh
Mikail tarafından Kureyş adı verilmiştir. Nüfus kütüğünde adı Mahmut'tur. 1268
tarihinde Hak'ka yürüyen Seyyit Mahmut Hayrani hakkında daha geniş bilgi
Cumhuriyet Halk Partisi Genel Başkanı Sayın Kemal Kılıçdaroğlu'nun arşivinden
alınan ve günümüz Türkçesine de çevrilen belgelerin yer aldığı, sayın mil-
letvekili Ensar Öğüt ile Mesut Özcan tarafından hazırlanan Kureyşan Ocağı isimli
kitapta daha detaylı bilgiler vardır. Kendilerine teşekkür ediyorum.  
    </chakra.p>
    <chakra.p mt={5} fontFamily={'Work Sans'} fontWeight={'medium'} fontSize={18} color={"#000"} >
    Osmanlı döneminde önceki İslam devletlerinden intikal eden kurumlardan biri de
Nakibu'l-eşraflıktır.
Nakibu'l-eşraflar, Hz. Peygamber soyundan (sülalesinden) olan Seyyid ve
şeriflerin amir ve reisi olup, Osmanlı ülkesindeki Seyyid ve Şeriflerin nezareti ile
onların şecere denilen silsilenamelerini kayıt ve zapteden memurlardır.  
Memlük devletinde de bulunan bu makam Osmanlılarda ilk devirlerinde müstakil
ise de sonradan ilmiye teşkilatına bağlanmıştır.
    </chakra.p>
    <chakra.p mt={5} fontFamily={'Work Sans'} fontWeight={'medium'} fontSize={18} color={"#000"} >
    Hz. Peygamber'in kızı Fatımatu Zehra ile evli olan İmam Ali evlatlarından
gelenler seyyid ve şerif adlarıyla tesmiye olunmuştur. Osmanlı devleti kurulduk-
tan sonra daha önceki Türk devletlerinde olduğu gibi seyyid ve şerifler için bir
teşkilat oluşturulmuştur. Şerif, Hz. Peygamber'in Ehli Beyiti'ne mensup olanlara
ceddi Peygamberden gelenlere denilmiştir. Bu ünvan hicretin IV. asrında
Abbasiler zamanında Ensab nikabeti adıyla yalnız Ali-i Ebu Talip, yani İmam Ali
evladıyla Ali Abbas'a, yani Abbas bin Abdülmutalip evladına tahsis edilmiştir.  
    </chakra.p>
    <chakra.p mt={5} fontFamily={'Work Sans'} fontWeight={'medium'} fontSize={18} color={"#000"} >
    Fatimiye devleti zamanında (910-1117) Mısır'da şerif ünvanı Hz. İmam Hasan'a
ve seyyid ünvanı İmam Hüseyin'e ve evlatlarına mahsustur. Daha sonra İmam Ha-
san evlatlarına şerif, İmam Hüseyin evlatlarına seyyid ünvanı verilmesi
benimsenmiştir.  
    </chakra.p>
    <ul>
      <li>
      <chakra.p  fontFamily={'Work Sans'} fontWeight={'medium'} fontSize={18} color={"#000"} >
      1100 ve 1134 tarihli siyaset beratları özellikleri ve zikredilen kayıtlardaki yeri:
Ramazanın 1100/Nisan 1689 tarihidir. Sultan II. Süleyman dönemine aittir. Şeyh
Mahmud'un nesli kibardan olması sebebiyle silsileye mahil ve müstahak olduğuna
dair berat verilmiştir.  
    </chakra.p>
      </li>
      <li>
      <chakra.p  fontFamily={'Work Sans'} fontWeight={'medium'} fontSize={18} color={"#000"} >
      Berat 27 Muharrem 1134/21 Ekim 1721 tarihlidir. Sultan III. Ahmet dönemine
aittir. Seyyid Seyhel-hac Yusuf'un elindeki mensur ile südde-i saadete baş vura-
rak mensuru şahiti olduğu anlaşıldığından silsile sahi- bine müdahale ve zulüm
olunmamasına devir berat ve- rildiği hakkındadır.  
    </chakra.p>
      </li>
      <li>
      <chakra.p  fontFamily={'Work Sans'} fontWeight={'medium'} fontSize={18} color={"#000"} >
      Şecere, kadılar tarafından tasdik olunmuştur.  
    </chakra.p>
      </li>
    </ul>
  
    <chakra.p mt={10} fontFamily={'Work Sans'} fontWeight={'bold'} fontSize={18} color={"#000"} >
    Mehmet GÜZEL 
    </chakra.p>
    <chakra.p  fontFamily={'Work Sans'} fontWeight={'bold'} fontSize={18} color={"#000"} >
Seyyid Hacı Kureyş Dergahı
Arş.Eğt. Kült. ve Sos. Yrd. Derneği Başkanı  
    </chakra.p>

  <Box w={"100%"} height={100} ></Box>
   </Container>
  )
}

export default Tarihce