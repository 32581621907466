import { Box, Flex, Grid, GridItem, Heading, Text } from '@chakra-ui/react'
import React from 'react'
import SCard from '../Components/SCard'

function Toplantı() {

  const toplanti = []
  return (
    <Box p={8} >
      <Flex mb={10} alignItems={"center"} flexDirection={"column"} >
        <Heading mb={10} mt={10} >TOPLANTILAR</Heading>
      </Flex>

      {toplanti.length > 0 ? <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", xl: "repeat(3, 1fr)" }} gap={6}>
        {toplanti.map((d) => (
          <GridItem key={d.name} display="flex" alignItems="center" justifyContent="center">
            <SCard text={d.text} header={d.title} img={d.img} date={d.date} yer={d.yer} />

          </GridItem>
        ))


        }
      </Grid> :
        <Flex minHeight={400} justifyContent={"center"} >
          <Text textAlign={"center"} >
            Henüz paylaşılmış bir toplantı yok
          </Text>

        </Flex>}
    </Box>
  )
}

export default Toplantı