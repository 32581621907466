import { Box, Button, Flex, HStack, Heading, VStack } from '@chakra-ui/react'
import React from 'react'
import { MdEmail, MdLocationOn, MdPhone } from 'react-icons/md'
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet'


const map = {
  center: [
    39.9277971,
    32.8533531
  ],
  zoom: 18
};

function Iletisim() {
  return (
    <Box p={6} >
        <Flex  alignItems={"center"} flexDirection={"column"} >
      <Heading mb={10} mt={10} >İLETİŞİM</Heading>
    </Flex>

    <MapContainer style={{ height: 500, width: '100%', minHeight: 150 }} center={map.center} zoom={map.zoom} scrollWheelZoom={false}>
          <TileLayer
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={map.center}>
            <Popup>
              Konumu  <a target='_blank' href="https://www.google.com/maps/place/%C4%B0lkirem+Lavanta+Bah%C3%A7esi/@40.8613863,35.2449688,15z/data=!4m6!3m5!1s0x4086e352b6b556c7:0x28674df1c9d35e04!8m2!3d40.8613863!4d35.2449688!16s%2Fg%2F11y1d37ypp?entry=ttu">Haritalar</a> Uygulamasında Aç
            </Popup>
          </Marker>
        </MapContainer>


      <Box display={"flex"} flexDirection={{base:"column",md:"row"}} alignItems={"center"} justifyContent={"space-between"} >
        <Button
          size="md"
          height="48px"
          variant="ghost"
          color="#000"
          leftIcon={<MdPhone color="#000" size="20px" />}>
          0312 232 55 54
        </Button>
        <Button
          size="md"
          height="48px"
          variant="ghost"
          color="#000"
          leftIcon={<MdEmail color="#000" size="20px" />}>
          uresanocagi@gmail.com
        </Button>
        <Button
          size="md"
          height="48px"
          variant="ghost"
          color="#000"
          leftIcon={<MdLocationOn color="#000" size="20px" />}>
           KORKUT REİS MAH. İLKİZ SOK. NO:20/4 SIHHIYE – 
  ANKARA
        </Button>
      </Box>
    </Box>
  )
}

export default Iletisim