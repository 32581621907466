import { ReactNode } from 'react';
import {
    Box,
    Flex,
    IconButton,
    useDisclosure,
    useColorModeValue,
    AccordionButton,
    Accordion,
    AccordionIcon,
    AccordionPanel,
    AccordionItem,
    Image,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';



const menus = [
    { name: "Anasayfa", url: "/" },
    {
        name: "Kurumsal", subs: [
            { name: "Yönetim Kurulu", url: "/YonetimKurulu" },
            { name: "Denetim Kurulu", url: "/DenetimKurulu" },
            { name: "Yayın Kurulu", url: "/YayinKurulu" },
            { name: "Tarihçe", url: "/Tarihce" },
            { name: "Şecere", url: "/Secere" },
            { name: "İlkelerimiz", url: "/Ilkeler" },
            { name: "Amaç ve Faaliyetler", url: "/Amac" },
        ]
    },
    {
        name: "Faaliyetlerimiz", subs: [
            { name: "Sempozyum", url: "/Sempozyum" },
            { name: "Toplantı", url: "/Toplantı" },
        ]
    },
    { name: "Şubelerimiz", url: "/Sube" },
    { name: "Formlar", url: "/Formlar" },
    { name: "Aidat ve Bağış", url: "/Odeme" },
    { name: "Galeri", url: "/Galeri" },
    { name: "Yayınlar", url: "/Yayimlar" },
    { name: "Köşe Yazısı", url: "/KoseYazisi" },

    { name: "İletişim", url: "/Iletisim" }
]


const FullMenu = ({ navigate }) => {
    return (
        <Box display={{ base: 'none', xl: 'flex' }} >
            <Accordion display={"flex"} allowMultiple={false} allowToggle defaultIndex={[0]} >
                {menus.map((d, i) => {
                    return (
                        <AccordionItem key={i}>
                            <h2>
                                <AccordionButton onClick={() => { d.url && navigate(d.url) }}  >
                                    <Box as='span' textAlign='left'>
                                        {d.name}
                                    </Box>
                                    {d.subs && <AccordionIcon />}
                                </AccordionButton>
                            </h2>

                            {d.subs && <AccordionPanel shadow={"base"} top={"80px"} flexDirection={"column"} position={"absolute"} width={"100vw"} left={0} bg={'gray.200'} display={"flex"} >
                                {d.subs && d.subs.map((dd, ii) => {
                                    return (
                                        <h2 key={ii}>
                                            <AccordionButton onClick={() => { dd.url && navigate(dd.url) }}  >
                                                <Box as='span' textAlign='left'>
                                                    {dd.name}
                                                </Box>
                                            </AccordionButton>
                                        </h2>
                                    )
                                })}
                            </AccordionPanel>}
                        </AccordionItem>
                    )
                })}
            </Accordion>
        </Box>
    )
}

const NavbarMenu = ({ navigate, onClick }) => {
    return (
        <Box display={"flex"} width={"100%"} height={"100vh"} >

            <Accordion width={"100%"} display={"flex"} flexDirection={"column"} allowMultiple={false} allowToggle defaultIndex={[1]} >
                {menus.map((d, i) => {
                    return (
                        <AccordionItem key={i}>
                            <h2>
                                <AccordionButton onClick={() => {
                                    d.url && navigate(d.url);
                                    d.url && onClick && onClick()
                                }}   >
                                    <Box as='span' textAlign='left'>
                                        {d.name}
                                    </Box>
                                    {d.subs && <AccordionIcon />}
                                </AccordionButton>
                            </h2>

                            {d.subs && <AccordionPanel bg={'gray.200'} >
                                {d.subs && d.subs.map((dd, ii) => {
                                    return (
                                        <h2 key={ii}>
                                            <AccordionButton
                                                onClick={() => {
                                                    dd.url && navigate(dd.url);
                                                    onClick && onClick()
                                                }}  >
                                                <Box as='span' textAlign='left'>
                                                    {dd.name}
                                                </Box>
                                            </AccordionButton>
                                        </h2>
                                    )
                                })}
                            </AccordionPanel>}
                        </AccordionItem>
                    )
                })}
            </Accordion>
        </Box>
    )
}

export default function AppBar() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const navigate = useNavigate()


    return (
        <>
            <Box position={"fixed"} zIndex={999} width={"100%"} bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
                <Flex  p={10} h={16} alignItems={'center'} justifyContent={'space-between'}>

                    <Box w={20} h={20} >
                        <Image objectFit='cover' src='/LOGO.jpg' />
                    </Box>


                    <IconButton
                        size={'md'}
                        icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                        aria-label={'Open Menu'}
                        display={{ xl: 'none' }}
                        onClick={isOpen ? onClose : onOpen}
                    />
                    <FullMenu navigate={navigate} />




                </Flex>
                {isOpen && <NavbarMenu onClick={() => onClose()} navigate={navigate} />}

            </Box>
        </>
    );
}