import { Box, Button, FormControl, FormLabel, Input, InputGroup, InputLeftAddon, InputLeftElement, Textarea, VStack, useDisclosure } from '@chakra-ui/react'
import React, { useState } from 'react'
import { BsPerson } from 'react-icons/bs'
import { MdOutlineEmail } from 'react-icons/md'
import { RiPhoneFill } from "react-icons/ri";
import { RiIdCardLine } from "react-icons/ri";
import emailjs from '@emailjs/browser'
import FAlert from './FAlert';
import Loading from './Loading';


function Foms() {

    const {
        isOpen: isVisible,
        onClose,
        onOpen,
      } = useDisclosure({ defaultIsOpen: false })
      
      const [alertt,setalertt]=useState(false)
      const [ss,setMsetssails]=useState({s:"success",text:"",title:""})
    const [mails,setMails]=useState(
        {name:"",email:"",tc:"",tel:""}
    )


    const sendEmail = (e) => {
    
        e.preventDefault();
        if (mails.name===""||mails.email===""||mails.tc===""||mails.tel==="") {
            setMsetssails({s:"error",text:"Lütfen Boş Olan Tüm Alanları Doldurun.",title:"Hata"})
            onOpen()
        }else{
           
        setalertt(true)
          
            
            emailjs
              .sendForm('service_ru2srsf', 'template_ap9fp3n', e.target, {
                publicKey: 'qeHq1JOMvWsCiBA4W',
              })
              .then(
                
                () => {
                    setalertt(false)
                //   console.log('SUCCESS!');
                  setMsetssails({s:"success",text:"Üyelik Formunuz Başarıyla Gönderildi.",title:"Tebrikler"})
              onOpen()
    
                },
                (error) => {
                    setalertt(false)
                    setMsetssails({s:"error",text:"Bir Hata Oluştu Lütfen Tekrar Deneyin.",title:"Hata"})
              onOpen()
    
                //   console.log('FAILED...', error.text);
                },
              );

        }


      };
    return (
        <Box mt={10} maxW={400} bg="gray.200" borderRadius="lg">
            <Loading display={alertt} />
            <FAlert status={ss.s} title={ss.title} text={ss.text} isVisible={isVisible} onClose={onClose} onOpen={onOpen} />
            <Box m={8} color="#0B0E3F">
                <VStack spacing={5}>
                <form onSubmit={sendEmail}>
                    <FormControl  id="name">
                        <FormLabel>İsim Soyisim</FormLabel>
                        <InputGroup borderColor="gray.500">
                         
                        <InputLeftAddon><BsPerson color="gray.800" /></InputLeftAddon>

                            <Input name="name" value={mails.name} onChange={e=>setMails({...mails,name:e.target.value})} type="text" size="md" />
                        </InputGroup>
                    </FormControl>
                    <FormControl id="email">
                        <FormLabel>E-Posta</FormLabel>
                        <InputGroup borderColor="gray.500">
                        
                        <InputLeftAddon><MdOutlineEmail color="gray.800" /></InputLeftAddon>

                            <Input name="email" value={mails.email} onChange={e=>setMails({...mails,email:e.target.value})}   type="text" size="md" />
                        </InputGroup>
                    </FormControl>
                    <FormControl  id="tc">
                        <FormLabel>Kimlik No</FormLabel>
                        <InputGroup borderColor="gray.500">
                        <InputLeftAddon><RiIdCardLine color="gray.800" /></InputLeftAddon>
                       
                            <Input name="tc" value={mails.tc} onChange={e=>setMails({...mails,tc:e.target.value})}  maxLength={11} type="tel" size="md" />
                        </InputGroup>
                    </FormControl>

                    <FormControl id="tel">
                        <FormLabel>Telefon</FormLabel>
                        <InputGroup borderColor="gray.500">
                        
                        <InputLeftAddon><RiPhoneFill color="gray.800" /></InputLeftAddon>

                            <Input name="tel" value={mails.tel} onChange={e=>setMails({...mails,tel:e.target.value})}  maxLength={10} size={"md"} type='tel'  />
                        </InputGroup>
                    </FormControl>

                    <FormControl mt={10} id="submit" float="right">
                        <Button
                        type='submit'
                            w={"100%"}
                            variant="solid"
                            bg="#0D74FF"
                            color="white"
                            _hover={{}}>
                            GÖNDER
                        </Button>
                    </FormControl>

                </form >
                </VStack>
            </Box>
        </Box>
    )
}

export default Foms