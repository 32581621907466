import { Box, Flex, Grid, GridItem, Heading } from '@chakra-ui/react'
import React from 'react'
import YCard from '../Components/YCard'

function YayinKurulu() {
  const yonetimKurulu = [
    { name: "FADİME ÖZCAN", Gorev: "YAYIN BAŞKANI", img: "/uyeler/2.jpeg", role: "ÜYE" },
  


  ]


  return (


    <Box p={5}>
      <Flex mb={10} justifyContent={"center"} >
        <Heading mt={10} >YAYIN KURULU</Heading>
      </Flex>

      <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", xl: "repeat(3, 1fr)" }} gap={6}>
        {yonetimKurulu.map((d) => (
          <GridItem key={d.name} w="100%" bg="blue.500" display="flex" alignItems="center" justifyContent="center">
            <YCard avatar={d.img} key={d.name} name={d.Gorev} role={d.role} content={d.name} />
          </GridItem>
        ))}
      </Grid>
    </Box>
  )
}

export default YayinKurulu